import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';
import { ScrollToModule } from 'ng2-scroll-to-el';
import { HttpClientModule } from '@angular/common/http'; 
import { FacebookModule } from 'ngx-facebook';

import { AppComponent } from './app.component';
import { TidComponent } from './shared/oppentider/tid.component';
import { ComponentsComponent } from './components/components.component';
import { OmossComponent } from './components/omoss/Omoss.component';
import {MenyComponent } from './components/meny/meny.component';
import {KontaktComponent} from './components/kontakt/kontakt.component';



@NgModule({
    declarations: [
        AppComponent,
        TidComponent,
        ComponentsComponent,
        OmossComponent,
        MenyComponent,
        KontaktComponent,
    ],
    imports: [
        BrowserAnimationsModule,
        NgbModule.forRoot(),
        ScrollToModule.forRoot(),
        FormsModule,
        RouterModule,
        HttpClientModule, 
        AppRoutingModule,
        ComponentsModule,
        FacebookModule.forRoot()
    ],
    exports:[
        FacebookModule
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { }
