import { Component, OnInit, ElementRef, ViewChild, Input } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';

@Component({
    selector: 'app-oppentider',
    templateUrl: './tid.component.html',
})



export class TidComponent implements OnInit {
    @ViewChild('inputMessage', { static: false })
    inputMessageRef: ElementRef;
    dynamicdata1: string = 'This is dynamic data!';
    @Input() dynamicdataa: ElementRef;
    @Input() dynamicdata: string;
    @Input() staticdata: string;
    private toggleButton: any;
    private sidebarVisible: boolean;
    constructor(public location: Location, private element : ElementRef) {
        this.sidebarVisible = false;
    }
    
    ngOnInit() {
        const navbar: HTMLElement = this.element.nativeElement;
        this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
    }
    sidebarOpen() {
        const toggleButton = this.toggleButton;
        const html = document.getElementsByTagName('html')[0];
        setTimeout(function(){
            toggleButton.classList.add('toggled');
        }, 500);
        html.classList.add('nav-open');

        this.sidebarVisible = true;
    };
    sidebarClose() {
        const html = document.getElementsByTagName('html')[0];
        // console.log(html);
        this.toggleButton.classList.remove('toggled');
        this.sidebarVisible = false;
        html.classList.remove('nav-open');
    };
    sidebarToggle() {
        const toggleButton = this.toggleButton;
        const body = document.getElementsByTagName('body')[0];
        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
    };
  
    isDocumentation() {
        var titlee = this.location.prepareExternalUrl(this.location.path());
        if( titlee === '/documentation' ) {
            return true;
        }
        else {
            return false;
        }
    }

      scroll(id) {
        console.log(`scrolling to ${id}`);
        let el = document.getElementById(id);
        el.scrollIntoView({ behavior: 'smooth' });
    }
}
