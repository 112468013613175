import { Input, Component, ViewChild, ElementRef } from '@angular/core';
import { HttpClient, HttpHeaders, HttpClientModule  } from '@angular/common/http'; 
import { Observable } from 'rxjs/Observable';


const httpOptions = {
    headers: new HttpHeaders({ 
      'Access-Control-Allow-Origin':'*',
      'Access-Control-Request-Method': 'GET'
    })
  };

@Component({
    selector: 'app-meny',
    templateUrl: './meny.component.html',
    styleUrls: ['./meny.component.scss']
})

export class MenyComponent {

    public PizzorCollapsed = true;
    public grillCollapsed = true;
    public pastaCollapsed = true;
    public salladCollapsed = true;
    public tacoCollapsed = true;
    public kebabCollapsed = true;
    public ovrigtCollapsed = true;

    
    public pizza: Pizza_meny[] = [];
    public ovrigt: ovrigt[] = [];


    constructor(private http: HttpClient){
        this.getPizza().subscribe(data => { 
            this.pizza = data
            //console.log(data)
        });

        this.getOvrigt().subscribe(data => {
            this.ovrigt = data
            //console.log(data)
        });
    }

    public getPizza(): Observable<Pizza_meny[]> {
        return this.http.get<Pizza_meny[]>('./assets/data/Pizza_meny.json')
    }

    public getOvrigt(): Observable<ovrigt[]> {
        return this.http.get<ovrigt[]>('./assets/data/ovrigt.json')
    }
}



export interface Pizza_meny {
    id: number;
    namn: string;
    pris_normal: number;
    pris_familj: number;
    beskrivning: string;
}

export interface ovrigt {
    id: number;
    menu_id: number;
    namn: string;
    pris: number;
    beskrivning: string;
}
