import { Component, OnInit, Renderer, OnDestroy, ViewChild, ElementRef, AfterViewInit, Input } from '@angular/core';
import { NgbAccordionConfig } from '@ng-bootstrap/ng-bootstrap';
import { ScrollToService } from 'ng2-scroll-to-el';



@Component({
    selector: 'app-components',
    templateUrl: './components.component.html',
    styles: [`
    ngb-progressbar {
        margin-top: 5rem;
    }
    `]
})

export class ComponentsComponent implements OnInit, OnDestroy, AfterViewInit {
    data: Date = new Date();
    @Input() element: any;
    page = 4;
    page1 = 5;
    page2 = 3;
    focus;
    focus1;
    focus2;

    date: { year: number, month: number };

    public isCollapsed = true;
    public isCollapsed1 = true;
    public isCollapsed2 = true;

    state_icon_primary = true;

    constructor(private renderer: Renderer, config: NgbAccordionConfig, private scrollService: ScrollToService) {
        config.closeOthers = true;
        config.type = 'info';
    }
    @Input() dynamicdataa: ElementRef;

    scrollToHome(element) {
        this.element = this.scrollService.scrollTo(element, 700, 0);
    }

    ngAfterViewInit() {
        console.log(this.element);
    }



    ngOnInit() {
        var navbar = document.getElementsByTagName('nav')[0];
        navbar.classList.add('navbar-transparent');
        var body = document.getElementsByTagName('body')[0];
        body.classList.add('index-page');
    }
    ngOnDestroy() {
        var navbar = document.getElementsByTagName('nav')[0];
        navbar.classList.remove('navbar-transparent');
        var body = document.getElementsByTagName('body')[0];
        body.classList.remove('index-page');
    }


}
