import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './kontakt.component.html'
})
export class KontaktComponent implements OnInit {
  year: number = new Date().getFullYear();
  constructor() {
   }

  ngOnInit() { }
}
