import { Component, OnInit, ViewChild , ElementRef, Input} from '@angular/core';
import * as Rellax from 'rellax';
import { ScrollToService } from 'ng2-scroll-to-el';

@Component({
    selector: 'app-omoss',
    templateUrl: './Omoss.component.html',
    styleUrls: ['./Omoss.component.scss']
})



export class OmossComponent implements OnInit {
    @Input() element:any;
    data : Date = new Date();
  focus;
  focus1;

  constructor(private scrollService: ScrollToService) { }

  ngOnInit() {
    var rellaxHeader = new Rellax('.rellax-header');

    var body = document.getElementsByTagName('body')[0];
    body.classList.add('landing-page');
    var navbar = document.getElementsByTagName('nav')[0];
    navbar.classList.add('navbar-transparent');
  }



    scrollToHome(element) {
      this.scrollService.scrollTo(element, 700, 0);
  }

  ngOnDestroy(){
    var body = document.getElementsByTagName('body')[0];
    body.classList.remove('landing-page');
    var navbar = document.getElementsByTagName('nav')[0];
    navbar.classList.remove('navbar-transparent');
  }

}
