import { Component, OnInit, Inject, Renderer, ElementRef, ViewChild, Input } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/filter';
import { LocationStrategy, PlatformLocation, Location } from '@angular/common';
import { TidComponent } from './shared/oppentider/tid.component';
import * as Rellax from 'rellax';
import { FacebookService, UIParams, UIResponse } from 'ngx-facebook';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    private _router: Subscription;
   // @ViewChild('inputMessage')
    inputMessageRef: ElementRef;
    dynamicdataa: ElementRef;
    dynamicdata: string = 'This is dynamic data!';
    @ViewChild(TidComponent, {read:TidComponent, static:false}) navbar: TidComponent;
    private toggleButton: any;
    private sidebarVisible: boolean;
    data : Date = new Date();
    showResponsiveImg = false;

    constructor( private renderer : Renderer, private fb: FacebookService, private router: Router, private element : ElementRef, public location: Location) {
        this.sidebarVisible = false;

        
    }


    share(url: string) {

        let params: UIParams = {
          href: 'https://github.com/zyra/ngx-facebook',
          method: 'share'
        };
      
        this.fb.ui(params)
          .then((res: UIResponse) => console.log(res))
          .catch((e: any) => console.error(e));

          this.fb.init({
            appId: '238215993632624',
            version: 'v2.9'
          });
    }


    

    ngOnInit() {
        var navbar : HTMLElement = this.element.nativeElement.children[0].children[0];
        this._router = this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: NavigationEnd) => {
            if (window.outerWidth > 991) {
                this.showResponsiveImg = true;
                window.document.children[0].scrollTop = 0;
            }else{
                window.document.activeElement.scrollTop = 0;
            }
        

            this.renderer.listenGlobal('window', 'scroll', (event) => {
                const number = window.scrollY;
                var _location = this.location.path();
                _location = _location.split('/')[2];

                if (number > 150 || window.pageYOffset > 150) {
                    navbar.classList.remove('navbar-transparent');
                } else if (_location !== 'login' && this.location.path() !== '/nucleoicons') {
                    // remove logic
                    navbar.classList.add('navbar-transparent');
                }
            });
        });
        const navbarMenu: HTMLElement = this.element.nativeElement;
        this.toggleButton = navbarMenu.getElementsByClassName('navbar-toggler')[0];

        var rellaxHeader = new Rellax('.rellax-header');

        var navbar = document.getElementsByTagName('nav')[0];
        navbar.classList.add('navbar-transparent');
        var body = document.getElementsByTagName('body')[0];
        body.classList.add('index-page');
        
    }
    ngOnDestroy(){
        var navbar = document.getElementsByTagName('nav')[0];
        navbar.classList.remove('navbar-transparent');
        var body = document.getElementsByTagName('body')[0];
        body.classList.remove('index-page');
    }
    scrollTo(){
        this.inputMessageRef.nativeElement.scrollIntoView();
    }

    sidebarOpen() {
        const toggleButton = this.toggleButton;
        const html = document.getElementsByTagName('html')[0];
        setTimeout(function(){
            toggleButton.classList.add('toggled');
        }, 500);
        html.classList.add('nav-open');

        this.sidebarVisible = true;
    };
    sidebarClose() {
        const html = document.getElementsByTagName('html')[0];
        // console.log(html);
        this.toggleButton.classList.remove('toggled');
        this.sidebarVisible = false;
        html.classList.remove('nav-open');
    };
    sidebarToggle() {
        const toggleButton = this.toggleButton;
        const body = document.getElementsByTagName('body')[0];
        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
    };

     scroll(id) {
        console.log(`scrolling to ${id}`);
        let el = document.getElementById(id);
        el.scrollIntoView({ behavior: 'smooth', block: "start", inline: "nearest" });
    }

    isDocumentation() {
        var titlee = this.location.prepareExternalUrl(this.location.path());
        if( titlee === '/documentation' ) {
            return true;
        }
        else {
            return false;
        }
    }


    


}
